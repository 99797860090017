import AntForm, { type AntSchema, useAntForm } from '@9troisquarts/ant-form'
import { Alert, Button, Col, ConfigProvider, Drawer, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { type CustomerType } from '../../types'
import { castAttributesFromDefinition, type ModelDefinitionType } from '@9troisquarts/wand'
import post from '../../../utils/httpPost'
import fr from 'antd/lib/locale/fr_FR'
import useI18n from '../../../utils/useI18n'
// @ts-expect-error
import whiteLogo from '../../../../assets/images/Qualimetrie-white-logo.svg'
import logo from '../../../../assets/images/Qualimetrie-logo.svg'
import { LeftOutlined } from '@ant-design/icons'
import SignUp from './SignUp'
import TranslationProvider from '../../../utils/TranslationProvider'
import last from 'lodash/last'
import ResetPassword from './ResetPassword'
import useWindowDimensions from '../../../utils/useWindowDimensions'

interface SignInProps {
  customer: CustomerType
  flash?: any
}

const customerDefinition: ModelDefinitionType = {
  email: 'String',
  password: 'Password'
}

const SignIn: React.FC<SignInProps> = props => {
  const { width } = useWindowDimensions()
  const isMobile = width < 768
  const {
    customer,
    flash
  } = props

  const { t } = useI18n()

  const [open, setDrawerOpen] = useState<boolean>(!!(isMobile || flash.length > 0))
  const [drawerContent, setDrawerContent] = useState<string>(isMobile || flash.length > 0 ? 'signIn' : '')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    setError(last(flash[0]))
  }, [flash])

  const onSignInOpen = () => {
    setDrawerContent('signIn')
    setDrawerOpen(true)
  }

  const onSignUpOpen = () => {
    setDrawerContent('signUp')
    setDrawerOpen(true)
  }

  const onDrawerClose = () => {
    setDrawerOpen(false)
    setError('')
  }

  const signInCustomer = (customer: CustomerType) => {
    post('/customers/sign_in', {
      ...customer
    }, 'customer')
  }

  const onSubmit = () => {
    signInCustomer(castAttributesFromDefinition(customerDefinition, object))
  }

  const { object, onChange } = useAntForm(customer || {})

  const SignInSchema: AntSchema = [
    {
      name: 'email',
      label: t('words.email'),
      input: {
        type: 'string',
        inputProps: {
          placeholder: 'paul.maria@gmail.com'
        }
      },
      colProps: {
        xs: 24,
        md: 24
      }
    },
    {
      name: 'password',
      label: t('words.password'),
      input: {
        type: 'password',
        inputProps: {
          placeholder: '******'
        }
      },
      colProps: {
        xs: 24,
        md: 24
      }
    },
    <div key='password-reset-link' style={{ textAlign: 'right' }}>
      <a className='password-forgotten' onClick={() => { setDrawerContent('resetPassword') }}>
        {t('sentences.forgotten_password')}
      </a>
    </div>
  ].filter(Boolean)

  return (
    <ConfigProvider locale={fr}>
        <Layout className='home-layout'>
            <div className='home-container'>
              <div style={{ textAlign: '-webkit-right' }}>
                <Button className='outlined-home-btn ' onClick={onSignInOpen}>
                {t('words.login')}
                </Button>
              </div>
              <div style={{ textAlign: 'center' }}>
                <img src={whiteLogo} width={203} height={113} />
              </div>
              <Row>
                <Col style={{ marginTop: 102 }} xs={24} md={{ span: 6, offset: 1 }}>
                  <div>
                    <Typography.Title level={1}>
                      {t('sentences.welcome_to_qualimetrie')}
                    </Typography.Title>
                    <Typography.Title level={1}>
                      {t('sentences.wish_to_become_customer')}
                    </Typography.Title>
                    <p>
                      {t('sentences.home_text_block')}
                    </p>
                    <a className='qualimetrie-link' href='https://www.qualimetrie.com/es' target='_blank' rel="noreferrer">
                      {t('sentences.discover_qualimetrie')}
                    </a>
                    <div style={{ marginTop: 28 }}>
                      <Button id="signUpButton" className='primary-btn' onClick={onSignUpOpen}>
                        {t('words.to_sign_up')}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Drawer
                placement="right"
                closable={true}
                onClose={onDrawerClose}
                open={open}
                width={isMobile ? '100%' : 800}
                closeIcon={
                  <div style={{ textDecoration: 'underline', display: 'inline-flex' }}>
                    <LeftOutlined style={{ marginRight: 8 }} /> {t('devise.shared.links.back')}
                  </div>
                }
              >
                <div style={{ textAlign: '-webkit-right' }}>
                  {drawerContent === 'signUp' && (
                    <Button className='outlined-btn' id="signInButton" onClick={() => { setDrawerContent('signIn') }}>
                      {t('words.login')}
                    </Button>
                  )}
                  {drawerContent === 'signIn' && (
                    <Button className='outlined-btn sign-up-button' onClick={() => { setDrawerContent('signUp') }}>
                      {t('words.to_sign_up')}
                    </Button>
                  )}
                </div>
                <div style={{ textAlign: 'center' }}>
                  <img src={logo} width={203} height={113}/>
                </div>
                {drawerContent === 'signUp' && (
                  <SignUp isMobile={isMobile} />
                )}
                {drawerContent === 'resetPassword' && (
                  <ResetPassword isMobile={isMobile}/>
                )}
                {drawerContent === 'signIn' && (
                  <>
                    { error && (
                      <div className={isMobile ? '' : 'wrapped-content'}>
                        <Alert
                          message={(
                            <div style={{ color: '#DB2828', fontSize: '16px' }}>
                              {error}
                            </div>
                          )}
                          type="error"
                          style={{ marginTop: 24 }}
                        />
                      </div>
                    )}
                  <div style={{ textAlign: 'center' }}>
                    <Typography.Title level={4} className={isMobile ? '' : 'wrapped-content'} style={{ marginTop: 24 }}>
                      {t('sentences.connect')}
                    </Typography.Title>
                  </div>
                  <AntForm
                    schema={SignInSchema}
                    object={object}
                    layout="vertical"
                    onChange={onChange}
                    onSubmit={onSubmit}
                    submitText={t('words.login')}
                    actionsWrapperProps={{
                      style: {
                        textAlign: 'center',
                        marginTop: 50
                      }
                    }}
                    className={isMobile ? '' : 'wrapped-content'}
                    submitButtonProps= {{
                      className: 'primary-btn'
                    }}
                  />
                </>
                )}
            </Drawer>
        </div>
        </Layout>
      </ConfigProvider>
  )
}

export default TranslationProvider(SignIn)
