import AntForm, { type AntSchema, useAntForm } from '@9troisquarts/ant-form'
import React, { useState } from 'react'
import { type CustomerType } from '../../types'
import { castAttributesFromDefinition, type ModelDefinitionType } from '@9troisquarts/wand'
import axiosClient from '../../../utils/axiosClient'
import useI18n from '../../../utils/useI18n'
import logo from '../../../../assets/images/Qualimetrie-logo.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Alert } from 'antd'
import useWindowDimensions from '../../../utils/useWindowDimensions'

interface ResetPasswordProps {
  customer: CustomerType
  location: any
}

const passwordDefinition: ModelDefinitionType = {
  password: 'Password',
  passwordConfirmation: 'Password'
}

const EditPassword: React.FC<ResetPasswordProps> = props => {
  const {
    customer
  } = props
  const [searchParams] = useSearchParams()
  const { t } = useI18n()
  const { width } = useWindowDimensions()
  const isMobile = width < 768
  const resetToken: string | undefined = searchParams.get('reset_password_token')?.toString()

  const EditPasswordSchema: AntSchema = [
    {
      name: 'password',
      label: t('activerecord.attributes.user.password'),
      input: {
        type: 'password'
      },
      colProps: {
        xs: 24,
        md: 24
      }
    },
    {
      name: 'passwordConfirmation',
      label: t('words.password_confirmation'),
      input: {
        type: 'password'
      },
      colProps: {
        xs: 24,
        md: 24
      }
    }
  ]

  const history = useNavigate()
  const { object, onChange } = useAntForm(customer || {})
  const [errors, setErrors] = useState<any | undefined>(undefined)
  const resetPassword = async (customer: CustomerType) => {
    await axiosClient.put('/customers/password', {
      customer: { ...customer }
    }).then(res => {
      if (res === undefined) {
        history('/')
        window.location.reload()
      }
    }).catch(err => {
      console.log(err)
      setErrors(err.errors)
    })
  }

  const onSubmit = () => {
    resetPassword({ ...castAttributesFromDefinition(passwordDefinition, object), resetPasswordToken: resetToken })
  }

  return (
    <div className='edit-password-wrapper'>
      <div style={{ textAlign: 'center' }}>
        <img src={logo} width={203} height={113} />
      </div>
      {errors?.resetPasswordToken && (
        <div className={isMobile ? '' : 'wrapped-content'}>
          <Alert type='error' message={t('devise.passwords.reset_token_already_used')} style={{ marginBottom: 16, marginTop: 16 }} />
        </div>
      )}
      <div style={{ marginTop: 28 }}>
        <AntForm
            schema={EditPasswordSchema}
            object={object}
            layout="vertical"
            errors={errors}
            onChange={onChange}
            onSubmit={onSubmit}
            submitText={t('sentences.reset_password')}
            className={isMobile ? '' : 'wrapped-content'}
            submitButtonProps= {{
              className: 'primary-btn'
            }}
            actionsWrapperProps={{
              style: {
                textAlign: 'center',
                marginTop: 50
              }
            }}
          />
      </div>
    </div>
  )
}

export default EditPassword
