// @ts-nocheck
import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';

import AntForm, { AntFormRailsNested, AntFormGooglePlaces } from "@9troisquarts/ant-form";
import Layout from './components/Layout';
import routes from "../front_devise/routes";
import TranslationProvider from "../../utils/TranslationProvider";
import graphqlClient from "../../utils/graphqlClient";
import flattenRoutes from "../../utils/flattenRoutes";
import moment from 'moment';
import 'moment/locale/fr'

import DropdownIndicator from "../shared/components/ReactSelect/DropdownIndicator";
import UserContext from "../../utils/UserContext";
import EnvironmentBanner from "../shared/components/EnvironmentBanner";

moment.locale('es');

AntForm.configure({
  locale: 'es',
  formProps: {
    layout: 'vertical',
  },
});

AntForm.addField('nested', {
  component: AntFormRailsNested,
  showFormItemError: false,
});

AntForm.addField('location', {
  component: AntFormGooglePlaces,
  apiKey: "AIzaSyBjMaDVfgY7BnO9B2NvsHkxCITSmmvh8eU",
  geocoding: true,
  autocompletionRequest: {
    componentRestrictions: { country: ['fr', 'es', 'and'] }
  },
  selectProps: {
    placeholder: 'Buscar una dirección...',
    noOptionsMessage: () => 'No hay resultados',
    loadingMessage: () => "Búsqueda en curso...",
    components: { DropdownIndicator },
    classNamePrefix: 'gem-react-select'
  }
});

type IProps = {
  user: any;
}

const { client } = graphqlClient({ url: '/graphql' });

const App: React.FC<IProps> = props => {
  const {
    environment
  } = props;

  return (
    <ConfigProvider locale={frFR}>
      <UserContext.Provider value={props.user}>
        <BrowserRouter history={history}>
          <ApolloProvider client={client}>
            <EnvironmentBanner environment={environment} />
            <Layout user={props.user}>
              <Routes>
                  {flattenRoutes(routes).map(route => {
                    const Component = route.component;
                    return (
                      <Route key={route.name} exact path={route.path} element={<Component />}/>
                    )
                  })}
              </Routes>
            </Layout>
          </ApolloProvider>
          </BrowserRouter>
      </UserContext.Provider>
    </ConfigProvider>
  )
}

export default TranslationProvider(App);
