import AntForm, { type AntSchema, useAntForm } from '@9troisquarts/ant-form'
import { Alert } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { type CustomerType } from '../../types'
import { castAttributesFromDefinition, type ModelDefinitionType } from '@9troisquarts/wand'
import axiosClient from '../../../utils/axiosClient'
import useI18n from '../../../utils/useI18n'

interface ResetPasswordProps {
  isMobile: boolean
}

const customerDefinition: ModelDefinitionType = {
  email: 'String'
}

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const {
    isMobile
  } = props
  const { t } = useI18n()

  const resetPassword = async (customer: CustomerType) => {
    await axiosClient.post('/customers/password', {
      customer: { ...customer }
    }).then((res) => {
      setAlertMessage({ message: t('sentences.reset_password_success'), type: 'success' })
    }).catch(err => {
      setErrors(err.errors)
    })
  }

  const onSubmit = (): void => {
    resetPassword(castAttributesFromDefinition(customerDefinition, object))
  }

  const { object, onChange } = useAntForm({})
  const [alertMessage, setAlertMessage] = useState<{ message: string, type: 'warning' | 'success' | 'info' | 'error' | undefined }>({ message: t('sentences.reset_password_instructions'), type: 'warning' })
  const [errors, setErrors] = useState<any | undefined>(undefined)

  const intl = useIntl()

  const ResetPasswordSchema: AntSchema = [
    {
      name: 'email',
      label: intl.formatMessage({ id: 'words.email' }),
      input: {
        type: 'string'
      },
      colProps: {
        xs: 24,
        md: 24
      }
    }
  ]

  return (
    <div style={{ marginTop: 24 }}>
      <div className={isMobile ? '' : 'wrapped-content'}>
        <Alert type={alertMessage.type} message={alertMessage.message} style={{ marginBottom: 16 }} />
      </div>
      <AntForm
        schema={ResetPasswordSchema}
        object={object}
        layout="vertical"
        errors={errors}
        onChange={onChange}
        onSubmit={onSubmit}
        submitText={intl.formatMessage({ id: 'sentences.reset_password' })}
        className={isMobile ? '' : 'wrapped-content'}
        submitButtonProps= {{
          className: 'primary-btn'
        }}
        actionsWrapperProps={{
          style: {
            textAlign: 'center',
            marginTop: 50
          }
        }}
      />
    </div>
  )
}

export default ResetPassword
