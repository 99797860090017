// @ts-nocheck
import React from 'react';
import { components } from 'react-select';
import { DownOutlined } from '@ant-design/icons'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <DownOutlined style={{ fontSize: 12 }} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
