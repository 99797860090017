// @ts-nocheck
import React from 'react';
import { Layout as AntdLayout } from 'antd';
const {
  Content,
  Footer,
} = AntdLayout;
import styles from './index.module.sass';

export function urlToList(url) {
  if (!url || url === '/') {
    return ['/'];
  }

  var urlList = url.split('/').filter(function (i) {
    return i;
  });
  return urlList.map(function (urlItem, index) {
    return "/".concat(urlList.slice(0, index + 1).join('/'));
  });
}

interface IProps {
  children: React.ReactNode;
  user: any;
};

const Layout: React.FC<IProps> = props => {
  const {
    children,
  } = props;

  return (
    <AntdLayout
      id="front-layout-gem"
    >
      <Content className={styles.contentWrapper}>
        {children}
      </Content>
      <Footer className="layout-footer" />
    </AntdLayout>
  );
};

export default Layout;
