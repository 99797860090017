import AntForm, { type AntSchema, useAntForm } from '@9troisquarts/ant-form'
import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ModelDefinition, type ModelDefinitionType } from '@9troisquarts/wand'
import axiosClient from '../../../utils/axiosClient'
import useI18n from '../../../utils/useI18n'

const customerDefinition: ModelDefinitionType = new ModelDefinition({
  firstName: 'String',
  lastName: 'String',
  lastNameTwo: 'String',
  password: 'Password',
  passwordConfirmation: 'Password',
  birthdate: 'Date',
  email: 'String',
  gender: 'String',
  phone: 'String',
  mobilePhone: 'String',
  addressOne: {
    type: 'Places',
    options: {
      formattedAddress: 'addressOne',
      administrativeLevelOne: 'administrativeLevelOne',
      administrativeLevelTwo: 'administrativeLevelTwo',
      zipCode: 'zipCode',
      countryIso: 'countryIso'
    }
  },
  dni: 'String',
  level: 'String',
  bankInformation: 'String',
  sollicitableEmail: 'Boolean',
  sollicitablePhone: 'Boolean'
})

interface SignUpProps {
  isMobile: boolean
}

const SignUp: React.FC<SignUpProps> = (props) => {
  const { isMobile } = props
  const { t } = useI18n()

  const { object, onChange } = useAntForm({ email: '', sollicitableEmail: true, sollicitablePhone: true })
  const [errors, setErrors] = useState<any | undefined>(undefined)

  const createCustomer = async (customer) => {
    await axiosClient.post('/customers', {
      customer: { ...customer }
    }).then((res) => {
      if (res === undefined) {
        window.location.reload()
      }
    }).catch(err => {
      setErrors(err.errors)
    })
  }
  const onSubmit = async () => { await createCustomer(customerDefinition.parse(object)) }

  // Returns an error when the customer enters an invalid address, ex: 'Madrid'
  // will return no zip code so we set the error on addressOne to display it on the form
  useEffect(() => {
    if (errors?.zipCode) errors.addressOne = t('errors.messages.invalid')
  }, [errors])

  const signUpSchema: AntSchema = [
    [
      {
        name: 'gender',
        required: true,
        label: t('words.gender'),
        input: {
          type: 'select',
          options: ['female', 'male', 'not_specified'].map((gender) => ({
            label: t(`active_record.attributes.customer.genders.${gender}`),
            value: gender
          }))
        },
        colProps: {
          xs: 24,
          md: 24
        }
      },
      {
        name: 'firstName',
        required: true,
        label: t('words.first_name'),
        input: {
          type: 'string'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      },
      {
        name: 'lastName',
        required: true,
        label: t('words.last_name'),
        input: {
          type: 'string'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      }
    ],
    [
      {
        name: 'lastNameTwo',
        label: t('words.last_name_two'),
        input: {
          type: 'string'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      }
    ],
    [
      {
        name: 'birthdate',
        required: true,
        label: t('words.birthdate'),
        input: {
          type: 'date',
          inputProps: {
            format: 'DD/MM/YYYY',
            showToday: false,
            placeholder: '01/01/1990'
          }
        },
        colProps: {
          xs: 24,
          md: 24
        }
      },
      {
        name: 'email',
        required: true,
        label: t('words.email'),
        input: {
          type: 'string'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      }
    ],
    [
      {
        name: 'password',
        required: true,
        label: t('words.password'),
        input: {
          type: 'password'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      },
      {
        name: 'passwordConfirmation',
        required: true,
        label: t('words.password_confirmation'),
        input: {
          type: 'password'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      }
    ],
    [
      {
        name: 'dni',
        required: true,
        label: t('words.dni'),
        input: {
          type: 'string'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      }
    ],
    [
      {
        name: 'addressOne',
        required: true,
        label: t('words.address'),
        input: {
          type: 'location'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      },
      {
        name: 'addressTwo',
        label: t('active_record.attributes.customer.address_two'),
        input: {
          type: 'string'
        },
        colProps: {
          xs: 24,
          md: 24
        }
      }
    ],
    [
      {
        name: 'mobilePhone',
        required: true,
        label: t('words.mobile_phone'),
        input: {
          type: 'string',
          inputProps: {
            placeholder: 'Ex: 650950950'
          }
        },
        colProps: {
          xs: 24,
          md: 24
        }
      },
      {
        name: 'phone',
        label: t('words.phone_number'),
        input: {
          type: 'string',
          inputProps: {
            placeholder: 'Ex: 950950950'
          }
        },
        colProps: {
          xs: 24,
          md: 24
        }
      },
      {
        name: 'sollicitablePhone',
        style: {
          marginBottom: 0,
          marginLeft: 8
        },
        input: {
          type: 'checkbox',
          text: t('sentences.sollicitable_phone')
        }
      },
      {
        name: 'sollicitableEmail',
        style: {
          marginLeft: 8
        },
        input: {
          type: 'checkbox',
          text: t('sentences.sollicitable_email')
        }
      }
    ]
  ]

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={4} className={isMobile ? '' : 'wrapped-content'} style={{ marginTop: 24 }}>
          {t('words.sign_up')}
        </Typography.Title>
      </div>
      <AntForm
        schema={signUpSchema}
        object={object}
        layout="vertical"
        onChange={onChange}
        onSubmit={onSubmit}
        errors={errors}
        submitText={t('words.save')}
        actionsWrapperProps={{
          style: {
            textAlign: 'center',
            marginTop: 50
          }
        }}
        submitButtonProps= {{
          className: 'primary-btn'
        }}
        className={isMobile ? '' : 'wrapped-content'}
      />
    </>
  )
}

export default SignUp
